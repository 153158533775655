<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      dense
      class="mb-5"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Vaga - {{ job.title }} - ID {{ job.id }}</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <list-candidates :job-id-prop="job.id" />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>

export default {
  name: 'CandidatesByJobId',
  components: {
    'list-candidates': () => import('../candidates/List.vue'),
  },
  props: {
    populateWith: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      job: {
        id: null,
        title: '',
      },
    };
  },
  created() {
    this.loadJobData();
  },

  methods: {
    loadJobData() {
      if (this.populateWith) {
        this.job.id = this.populateWith.id;
        this.job.title = this.populateWith.title;
      }
    },

    close() {
      this.$emit('close-dialog');
    },
  },

};
</script>
